<template>
  <article :class="`ag-block--note-simple5--has-${note.image_display}-image`">
    <Image :media="note.featured_image" :alt="note.title" conversion="cover" lazy responsive class="ag-block__featured-image" />

    <span v-if="note.image_display === 'full'" class="ag-block__full-image">
      <Image :media="note.featured_image" :alt="note.title" conversion="cover" lazy responsive />
    </span>

    <div class="ag-block__body">
      <div class="ag-block__body-inner">
        <h3 v-if="note.title" class="ag-block__title" v-html="$markdown(note.title)" />
        <p v-if="note.synopsis" class="ag-block__description" v-html="note.synopsis"></p>
        <div v-if="note.url && note.call_to_action_text" class="ag-block__cta">
          <Button :href="note.url" :target="target" as="a" class="ag-block__button" rel="nofollow noindex" @click.prevent="onClick(block)">
            {{ note.call_to_action_text }}
          </Button>
        </div>
      </div>
    </div>
  </article>
</template>

<script setup lang="ts">
const props = defineProps<{
  block: Block;
}>();

const note: NoteBlock = props.block.item;

const target = note?.url_type === "link" ? "_blank" : undefined;

const { onClick } = useBlock();
</script>

<style lang="postcss">
.ag-block--note-simple5 {
  @apply flex flex-col overflow-hidden rounded-lg bg-slate-900;

  .ag-block__featured-image {
    @apply absolute inset-0 h-full w-full object-cover;
  }

  .ag-block__full-image {
    @apply relative z-20 m-4 mb-0 overflow-hidden rounded-lg;
  }

  .ag-block__body {
    @apply relative w-full p-8;

    &-inner {
      @apply relative z-20;
    }
  }

  .ag-block__title,
  .ag-block__title a {
    @apply text-white;
  }

  .ag-block__description {
    @apply text-white;
  }

  &--has-cover-image {
    @apply justify-end pt-60;

    .ag-block__body {
      &::before {
        @apply absolute inset-0 bg-gradient-to-t from-slate-900;
        content: "";
      }
    }
  }

  &--has-full-image {
    @apply justify-between;

    &::before,
    &::after {
      @apply absolute inset-0 z-10;
      content: "";
    }

    &::before {
      @apply backdrop-blur-sm;
    }

    &::after {
      @apply bg-gradient-to-t from-slate-900;
    }
  }
}
</style>
